
import './index.css'
import CasesFiled from './Components/CasesFiled';
import CasesSettled from './Components/CasesSettled';
import AboutSama from './Components/AboutSama';
import { useEffect, useState } from 'react';
import axios from 'axios';
import LeaderBoard from './Components/Leaderboard';

function App() {

const [filed, setFiled] = useState(true);
const [settled, setSettled] = useState(false);
const [about, setAbout] = useState(false);
const [leaderboard, setLeaderboard] = useState(false);

const [view, setView] = useState(true);

function changeSettled(active) {
  setSettled(!active);
  setFiled(false);
  setAbout(false);
  setLeaderboard(false);
  setView(false);
}
function changeFiled(active) {
  setSettled(false);
  setFiled(!active);
  setAbout(false);
  setLeaderboard(false);
  setView(true);
}
function changeAbout(active) {
  setSettled(false);
  setFiled(false);
  setAbout(!active);
  setLeaderboard(false);
  setView(false);
}

function changeBoard(active) {
  setSettled(false);
  setFiled(false);
  setAbout(false);
  setLeaderboard(!active);
  setView(false);
}

  return (
    <div id='containeria'>
      <CasesFiled click={changeFiled} act={filed} onemoreprop={view} />
      <CasesSettled click={changeSettled} act={settled}/>
      <LeaderBoard click={changeBoard} act={leaderboard} />
      <AboutSama click={changeAbout} act={about}/>
    </div>
  );
}

export default App;
