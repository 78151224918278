import axios from 'axios';
import { useEffect, useState } from 'react';
import '../index.css';

function LeaderBoard(props) {

    const [leaderboard] = useState(false);
    const [neutralsactive, setNeutralsActive] = useState(true);
    const [cmActive, setCmActive] = useState(false);
    const [neutrals, setNeutrals] = useState()
    const [cm, setCM] = useState()

    const handleNeutral = () => {
        setNeutralsActive(true)
        setCmActive(false)
    }

    const handleCM = () => {
        setCmActive(true)
        setNeutralsActive(false)
    }

    const getNeutrals = () => {
        axios.get(`${process.env.REACT_APP_BASE_PATH}/scoreboard/neutral`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                console.log(response.data.data)
                setNeutrals(response.data.data);
            })
            .catch(error => console.error(`Error: $(error)`));
    }

    const getCMs = () => {
        axios.get(`${process.env.REACT_APP_BASE_PATH}/scoreboard/casemanager`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                console.log(response.data.data)
                setCM(response.data.data);
            })
            .catch(error => console.error(`Error: $(error)`));
    }

    useEffect(() => {
        getNeutrals();
        getCMs();
    }, []);

    return (
        <div className={`panel ${props.act ? "active" : ""}`} onClick={() => props.click(leaderboard)}>
            {console.log(props)}
            <div className='row'>
                {!props.act && (
                    <div className='side4 sides col-sm-1'>
                        <p className="sidetext" style={{ animation: 'fadeIn 0.65s' }}>LEADERBOARD</p>
                    </div>)}
                {props.act && (<>
                    <div className='sides' style={{ backgroundColor: 'rgba(64, 64, 64, 0.64)', color: '#f2f2f2', width: '4.55%' }}>
                        <p className="sidetext">LEADERBOARD</p>
                    </div>
                    <div className='fill' style={{ width: '95.45%', backgroundColor: '#BEBEBE' }}>
                        <p className="header2" style={{ animation: 'fadeIn 1.05s', color: '#f2f2f2' }}>SAMA LEADERBOARD</p>
                        {/* <p className="stats" style={{animation: 'fadeIn 1.05s', color: '#f2f2f2'}}>54647567</p> */}
                        <div className='leaderboard'  style={{ animation: 'fadeIn 0.65s' }}>
                            <div className='leaderboardnav'>
                                <button className={`lbtn ${neutralsactive ? "active" : ""}`} onClick={handleNeutral}>Neutrals</button>
                                <button className={`lbtn ${cmActive ? "active" : ""}`} onClick={handleCM} >Case Managers</button>
                            </div>
                            <div className='leaders'>
                                {neutralsactive ? (
                                    <>
                                        {neutrals?.map((neutral, k) => {
                                            return (<div className='leader'>
                                                <div className='lead'>
                                                    <p style={{ margin: 0 }}>{k + 1}</p>
                                                    <p style={{ margin: 0 }}>{neutral?.name}</p>
                                                </div>
                                                <p style={{ margin: 0 }}>{neutral?.totalPoints} Pts</p>
                                            </div>)
                                        })}
                                    </>
                                ) : (
                                    <>
                                        {cm?.map((cm, k) => {
                                            return (<div className='leader'>
                                                <div className='lead'>
                                                    <p style={{ margin: 0 }}>{k + 1}</p>
                                                    <p style={{ margin: 0 }}>{cm?.name}</p>
                                                </div>
                                                <p style={{ margin: 0 }}>{cm?.totalPoints} Pts</p>
                                            </div>)
                                        })}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>)}
            </div>
        </div>
    )
}

export default LeaderBoard;